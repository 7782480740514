import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import AuthLayout from '~/features/layouts/AuthLayout';
import Button from '~/shared/ui/button';
import FormActions from '~/shared/ui/form-actions';
import { PanelTitle, PanelIntro } from '~/shared/ui/panel';

function LoginIndex() {
  const { t } = useTranslation('auth');

  // Placeholder content
  return (
    <AuthLayout>
      <PanelTitle>{t('intro.title')}</PanelTitle>
      <PanelIntro>{t('intro.intro')}</PanelIntro>

      <FormActions justify="flex-start">
        <Button as={Link} href="/login/school" iconAfter="arrow-right">
          {t('intro.button_school')}
        </Button>

        <Button as={Link} href="/login/thuis" iconAfter="arrow-right">
          {t('intro.button_home')}
        </Button>
      </FormActions>
    </AuthLayout>
  );
}

LoginIndex.options = {
  showSplash: true,
  isPublic: true,
  isLogin: true,
};

export default LoginIndex;
